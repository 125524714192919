module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pack Trading Group","short_name":"Pack Trade","description":"Your Partner in International Trade","lang":"en","display":"standalone","icon":"static/icon.jpg","icon_options":{"purpose":"any maskable"},"start_url":"/","background_color":"#fff","theme_color":"#fff","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"4b4fee675f459edee8f2d071a45df285"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_US","url":"https://pac-trade.com","site_name":"Pack Trading Group"},"language":"en"},
    }]
